import React, { useEffect, useState } from 'react';
import { Box, useTheme, Typography, Button, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";

import { sendPostData, getPostData } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

export const FormRcd = ({ secValue, secName, regID }) => {
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const { config } = UseAppConfig();
	const isEditing = !!regID;

	const [formValues, setFormValues] = useState({
		nombre: '',
		descripcion: '',
		simbolo: '',
		estado: 'Activado',
		nombreCatalogo: secValue,
		ubicacion: '',
	});

	const fetchData = async () => {
		if (isEditing) {
			try {
				const jsonData = await getPostData(`${config.API_BASE_URL}/detallesCatalogo/${secValue}`, { id: regID });
				if (jsonData && jsonData.length > 0) {
					const row = jsonData[0];
					const formattedData = {
						rcdID: row.id || '',
						nombre: row.nombre || '',
						descripcion: row.descripcion || '',
						simbolo: row.simbolo || '',
						ubicacion: row.ubicacion || '',
						imagen: row.imagen || '',
						seccion: row.seccion || '',
						estado: row.status === 1 ? 'Activo' : 'Desactivado',
						nombreCatalogo: secValue,
					};
					setFormValues(formattedData);
				} else {
					console.warn('No se encontraron datos en la respuesta del API');
				}
			} catch (error) {
				console.error('Error al obtener los datos:', error);
			}
		}
	};

	useEffect(() => {
		if (isEditing) {
			fetchData();
		}
	}, [regID]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		await handleFormSubmit(formValues);
		handleModalClose();
	};

	const handleFormSubmit = async (values) => {
		console.log(('El Catálogo seleccioando es: ' + secValue));
		
		try {
			const url = isEditing
				? `${config.API_BASE_URL}/editaCatalogo/${secValue}`
				: `${config.API_BASE_URL}/nuevo/catalogo`;
			const result = await sendPostData(url, values);
			enqueueSnackbar(isEditing ? 'Datos Actualizados' : 'Proyecto Guardado', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
		}
	};

	const handleModalClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				{isEditing ? `Edición del registro ${formValues.nombre}` : `Agregar nuevo registro al ${secName}`}
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Nombre del Registro"
					variant="outlined"
					name="nombre"
					value={formValues.nombre}
					onChange={handleChange}
					sx={{
						width: '100%',
						maxWidth: '400px'
					}}
				/>
				<TextField
					required
					id="project-description"
					label="Estado"
					variant="outlined"
					name="estado"
					value={formValues.estado}
					onChange={handleChange}
					disabled={isEditing}
					sx={{
						width: '100%',
						maxWidth: '200px'
					}}
				/>
				{secValue === 'familia' || secValue === 'usuarios' ? (
					<TextField
						required
						id="project-description"
						label="Descripción"
						variant="outlined"
						name="descripcion"
						value={formValues.descripcion}
						onChange={handleChange}
						multiline
						rows={10}
					/>
				) : secValue === 'ubicacion' ? (
					<TextField
						required
						id="project-description"
						label="Ubicación"
						variant="outlined"
						name="ubicacion"
						value={formValues.ubicacion}
						onChange={handleChange}
						rows={4}
						fullWidth
					/>
				) : secValue === 'iconos' ? (
					<>
						<TextField
							required
							id="project-title"
							label="Imagen"
							variant="outlined"
							name="imagen"
							value={formValues.imagen}
							onChange={handleChange}
							sx={{
								width: '100%',
								maxWidth: '300px'
							}}
						/>
						<TextField
							required
							id="project-description"
							label="Seccion"
							variant="outlined"
							name="seccion"
							value={formValues.seccion}
							onChange={handleChange}
							sx={{
								width: '100%',
								maxWidth: '300px'
							}}
						/>
					</>
				) : secValue === 'polizas' ? (
					<TextField
						required
						id="project-title"
						label="Símbolo"
						variant="outlined"
						name="simbolo"
						value={formValues.simbolo}
						onChange={handleChange}
						sx={{
							width: '100%',
							maxWidth: '200px'
						}}
					/>
				) : null}
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSubmit}
				>
					{isEditing ? 'Actualizar Datos' : 'Agregar Registro'}
				</Button>
			</Box>
		</div>
	);
};
