import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, Button, Modal, Switch, Typography } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";

import { tokens } from "../../data/tokens";
import { UseAppConfig } from '../../system/config';
import { Header, SmallHeader } from "../basics/Header";

import { sendPostData, getPostData, getDataArray } from '../../system/getData';

import ConfirmationDialog from "../globals/confirmation";
import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import ModalContent from "../compounds/modalDetails";
import { FormRcd } from "../catalogs/addCatalogs";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const DataTable = ({ data }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const colors = tokens(theme.palette.mode);

    const [open, setStatusOpen] = useState(false);
    const [dataTable, setDataTable] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const [statusRcdID, setStatusRcdID] = useState('');
    const [statusValue, setStatusValue] = useState('');

    const location = useLocation();
    const navigate = useNavigate();

    const { config, configData } = UseAppConfig();

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });

            let formattedData;
            const formatNumber = data.tableHeader.some(rcd => rcd.currency == 1);

            console.log('Los filtros de busqueda son: ' + params);

            formattedData = jsonData.map(row => ({
                ...row,
                statusValue: row.status,
                status: row.status === 1 ? 'Activado' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds',
                precioCompra: formatCurrency(row.precioCompra)
            }));

            setDataTable(formattedData);
            console.log(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    const articleDetails = async (data) => {
        try {
            const jsonData = await getDataArray(`${config.API_BASE_URL}/datos/registro`, { id: data });
            setModalContent(jsonData[0]);
            console.log('Detalles del Registro: ' + JSON.stringify(jsonData[0]));
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.endpoint]);

    const handleSearchSubmit = (values) => {
        fetchDataTable(values);
        console.log('El Filtro de Búsqueda seleccionado es: ' + JSON.stringify(values));
    };

    const handleFormDelete = async (thisID) => {
        enqueueSnackbar('Registro: ' + thisID + ' Eliminado', { variant: 'error' });
    };

    const handleArticlesDetails = (data) => {
        articleDetails(data)
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent('');
    };

    const handleCellClick = (params) => {
        const section = location.pathname.split('/')[1];
        switch (section) {
            case 'articles':
                const articleType = params.row.tipo;
                if (articleType === 'Simple') {
                    navigate(`${data.editLink1}/${params.id}`);
                } else {
                    navigate(`${data.editLink2}/${params.id}`);
                }
                break;
            default:
                navigate(`${data.editLink1}/${params.id}`);
                break;
        }
    };

    const handleStatusOpen = (params) => {
        setStatusRcdID(params.id);
        setStatusValue(params.statusValue);
        setStatusOpen(true);
    };

    const handleStatusClose = () => {
        setStatusOpen(false);
    };

    const handleConfirm = async () => {
        try {
            const url = `${config.API_BASE_URL}/edita/estado`;
            const params = { id: statusRcdID, status: statusValue, section: 'articulo' };
            const result = await sendPostData(url, params);
            enqueueSnackbar('Estado del Artículo actualizado exitosamente', { variant: 'success' });
            setStatusOpen(false);
            fetchDataTable();
        } catch (error) {
            console.error('Error al intentar actualizar el Estado', error);
            enqueueSnackbar('Error al intentar actualizar el Estado de este Artículo', { variant: 'error' });
            setStatusOpen(false);
        }
        console.log('Confirmado');
    };

    const StatusSwitch = ({ row, handleStatusOpen }) => {
        const handleChange = (event) => {
            handleStatusOpen(row);
        };

        return (
            <Switch
                checked={row.status === 'Activado'}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'status switch' }}
            />
        );
    };

    const columns = data.tableHeader.map(column => {
        if (column.modal) {
            switch (column.field) {
                case 'clave':
                    column.renderCell = ({ row }) => {
                        return (
                            <Button
                                variant="contained"
                                color={row.tipo === 'Compuesto' ? 'info' : 'inherit'}
                                style={{ width: '70px', height: '30px' }}
                                onClick={row.tipo === 'Compuesto' ? () => handleArticlesDetails(row.id) : null}
                            >
                                {row.clave}
                            </Button>
                        );
                    };
                    break;
            }
        } else if (column.botons) {
            column.renderCell = ({ row }) => {
                const botones = Object.keys(column.botons).map((botonKey) => {
                    const boton = column.botons[botonKey];
                    switch (column.field) {
                        case 'status':
                            return (
                                <StatusSwitch key={row.id} row={row} handleStatusOpen={handleStatusOpen} />
                            );
                        case 'acciones':
                            if (data.module === 'articles' || data.module === 'compounds') {
                                switch (row.module) {
                                    case 'articles':
                                    case 'compounds':
                                        data.module = row.module;
                                        break;
                                }
                            }
                            return (
                                boton.label === 'Eliminar' ? (
                                    <Button
                                        key={boton.label}
                                        variant="contained"
                                        color={boton.class}
                                        onClick={() => handleFormDelete(row.id)}
                                        style={{ marginRight: '5px', minWidth: '25px' }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                ) : (
                                    <Link key={boton.label} to={`/${data.module}/${boton.action.replace('${row.id}', row.id)}`} style={{ marginRight: '5px' }}>
                                        <Button variant="contained" color={boton.class} style={{ minWidth: '25px' }}><FontAwesomeIcon icon={faEdit} /></Button>
                                    </Link>
                                )
                            );
                    }
                });

                return (
                    <Box m="0 auto" p="5px" display="flex" justifyContent="center">
                        {botones}
                    </Box>
                );
            };
        }
        return column;
    });

    return (
        <Box m="20px">
            <Header title={data.title} subtitle={data.description} />
            {data.searchParams?.length > 0 ? (
                <SearchFilters data={data.searchParams} onSubmit={handleSearchSubmit} />
            ) : (
                null
            )}
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-10px"
                height="100vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle": { color: colors.grey[900] },
                }}
            >
                <Box display="flex" justifyContent="end">
                    <Link
                        key='Agregar Nuevo'
                        to={`/${data.module}/addnew`}
                        style={{ position: 'absolute', top: '60px', zIndex: 100000 }}
                    >
                        <Button variant="contained" color="success">
                            Agregar Nuevo
                        </Button>
                    </Link>
                </Box>
                <DataGrid
                    rows={dataTable}
                    columns={columns}
                    className="datos-tabla"
                    onCellClick={data.editLink1 ? handleCellClick : undefined}
                />
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalContent data={data} modalContent={modalContent}></ModalContent>
                </Modal>
                <ConfirmationDialog
                    open={open}
                    onClose={handleStatusClose}
                    onConfirm={handleConfirm}
                    statusValue={statusValue}
                />
            </Box>
        </Box>
    );
};

export const ExpandableDataTable = ({ data }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const colors = tokens(theme.palette.mode);

    const [dataTable, setDataTable] = useState([]);
    const [firstContent, setFirstContent] = useState([]);
    const [secondContent, setSecondContent] = useState([]);
    const [visibleNestedTable, setVisibleNestedTable] = useState(null);
    const [visibleThirdTable, setVisibleThirdTable] = useState({});

    const [open, setStatusOpen] = useState(false);
    const [statusRcdID, setStatusRcdID] = useState('');
    const [statusValue, setStatusValue] = useState('');

    const { config, configData } = UseAppConfig();

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });
            const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

            console.log('Los filtros de busqueda son: ' + params);

            const formattedData = jsonData.map(row => {
                const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
                    row.precioCompra,
                    row.precioVenta,
                    porcentajeGlobal
                );

                return {
                    ...row,
                    statusValue: row.status,
                    status: row.status === 1 ? 'Activado' : 'Desactivado',
                    module: row.tipo === 'Simple' ? 'articles' : 'compounds',
                    precioCompra: formatCurrency(row.precioCompra),
                    precioVenta: formatCurrency(precioVentaFormatted),
                    isPrecioVentaMenor
                };
            });

            setDataTable(formattedData);
            console.log(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            if (data.endpointParams) {
                fetchDataTable(data.endpointParams);
            } else {
                fetchDataTable();
            }
        }
    }, [data.endpoint]);

    const articleDetails = async (id, isThirdTable = false) => {
        try {
            const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.getData}`, { id });
            const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);

            const formatData = jsonData.map(row => {
                const total = row.precio * row.cantidad;
                const totalVenta = row.precioVenta * row.cantidad;
                const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
                return {
                    ...row,
                    statusElemento: row.statusElemento == 1 ? 'Activo' : 'Desactivado',
                    cantidadAnterior: row.cantidad,
                    total: formatCurrency(total),
                    precioVenta: formatCurrency(totalVenta),
                    percentage: percentage.toFixed(2) + '%'
                };
            });

            if (isThirdTable) {
                setSecondContent(formatData);
            } else {
                setFirstContent(formatData);
            }

            console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
            if (isThirdTable) {
                setSecondContent([]);
            } else {
                setFirstContent([]);
            }
        }
    };

    const toggleNestedTable = (rowIndex, data) => {
        if (visibleNestedTable === rowIndex) {
            setVisibleNestedTable(null);
            setVisibleThirdTable(null);
        } else {
            setVisibleNestedTable(null);
            setVisibleThirdTable(null);
            articleDetails(data);
            setTimeout(() => {
                setVisibleNestedTable(rowIndex);
            }, 1000);
        }
    };

    const toggleThirdTable = async (rowIndex, data) => {
        if (visibleThirdTable === rowIndex) {
            setVisibleThirdTable(null);
        } else {
            setVisibleThirdTable(null);
            await articleDetails(data, true);
            setTimeout(() => {
                setVisibleThirdTable(rowIndex);
            }, 1000);
        }
    };

    const handleStatusOpen = (params) => {
        setStatusRcdID(params.id);
        setStatusValue(params.statusValue);
        setStatusOpen(true);
    };

    const handleStatusClose = () => {
        setStatusOpen(false);
    };

    const handleConfirm = async () => {
        try {
            const url = `${config.API_BASE_URL}/edita/estado`;
            const params = { id: statusRcdID, status: statusValue, section: 'articulo' };
            const result = await sendPostData(url, params);
            fetchDataTable();
            setStatusOpen(false);
            setVisibleNestedTable(null);
            setVisibleThirdTable(null);
            enqueueSnackbar('Estado del Artículo actualizado exitosamente', { variant: 'success' });
        } catch (error) {
            console.error('Error al intentar actualizar el Estado', error);
            enqueueSnackbar('Error al intentar actualizar el Estado de este Artículo', { variant: 'error' });
            setStatusOpen(false);
        }
        console.log('Confirmado');
    };

    const StatusSwitch = ({ row, handleStatusOpen }) => {
        const handleChange = (event) => {
            handleStatusOpen(row);
        };
        return (
            <Switch
                checked={row.status === 'Activado'}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'status switch' }}
            />
        );
    };

    const handleSearchSubmit = (values) => {
        setVisibleNestedTable(null);
        setVisibleThirdTable(null);
        setTimeout(() => {
            fetchDataTable(values);
        }, 1000);
        console.log('El Filtro de Búsqueda seleccionado es: ' + JSON.stringify(values));
    };

    const handleFormDelete = async (thisID) => {
        enqueueSnackbar('Registro: ' + thisID + ' Eliminado', { variant: 'error' });
    };

    const NestedTable = ({ rowData }) => {
        const [nestedVisibleTable, setNestedVisibleTable] = useState(null);
        return (
            <Box m="0px">
                <Box
                    maxWidth="100%"
                    mx="auto"
                    mt="-25px"
                    sx={{
                        "& .MuiTable-root": { border: "none" },
                        "& .MuiTableCell-root": { borderBottom: "none" },
                        "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                        "& .MuiTableCell-head": { color: colors.grey[900] },
                        "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                    }}
                >
                    <TableContainer component={Paper} style={{ marginTop: 40, marginBottom: 20 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {data.subContentHeader.map((column, index) => (
                                        <TableCell key={index} style={{ padding: '10px 8px', textAlign: 'center' }}>{column.headerName}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {firstContent.map((row, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        <TableRow>
                                            {data.subContentHeader.map((column, colIndex) => (
                                                <TableCell
                                                    key={colIndex}
                                                    style={{ padding: '4px 8px', textAlign: 'center' }}
                                                    className={column.field === 'precioVenta' && row.isPrecioVentaMenor ? 'red-text' : ''}
                                                >
                                                    {column.collapse ? (
                                                        row.tipo == 2 ? (
                                                            <Button
                                                                variant="contained"
                                                                color={row.tipo == 2 ? 'info' : 'inherit'}
                                                                style={{
                                                                    width: '70px',
                                                                    height: '30px',
                                                                    cursor: row.tipo == 2 ? 'pointer' : 'default'
                                                                }}
                                                                onClick={row.tipo == 2 ? () => toggleThirdTable(rowIndex, row.rcdID) : null}
                                                            >
                                                                {row[column.field]}
                                                            </Button>
                                                        ) : (
                                                            <span>{row[column.field]}</span>
                                                        )
                                                    ) : (
                                                        row[column.field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={data.subContentHeader.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <Collapse in={visibleThirdTable === rowIndex} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <TableContainer
                                                            component={Paper}
                                                            maxWidth="100%"
                                                            mx="auto"
                                                            mt="-25px"
                                                            sx={{
                                                                "& .MuiTable-root": { border: "none" },
                                                                "& .MuiTableCell-root": { borderBottom: "none" },
                                                                "& .MuiTableHead-root": { backgroundColor: colors.primary[800], borderBottom: "none" },
                                                                "& .MuiTableCell-head": { color: colors.grey[100] },
                                                                "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                                                            }}
                                                        >
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {data.subContentHeader.map((column, index) => (
                                                                            <TableCell key={index} style={{ padding: '10px 8px', textAlign: 'center' }}>{column.headerName}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {secondContent.map((row, rowIndex) => (
                                                                        <React.Fragment key={rowIndex}>
                                                                            <TableRow style={{ backgroundColor: colors.grey[900] }}>
                                                                                {data.subContentHeader.map((column, colIndex) => (
                                                                                    <TableCell
                                                                                        key={colIndex}
                                                                                        style={{ padding: '4px 8px', textAlign: 'center' }}
                                                                                        className={column.field === 'precioVenta' && row.isPrecioVentaMenor ? 'red-text' : ''}
                                                                                    >
                                                                                        {column.collapse ? (
                                                                                            row.tipo == 2 ? (
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    color={row.tipo == 2 ? 'inherit' : 'inherit'}
                                                                                                    style={{ width: '70px', height: '30px', cursor: 'default' }}
                                                                                                >
                                                                                                    {row[column.field]}
                                                                                                </Button>
                                                                                            ) : (
                                                                                                <span>{row[column.field]}</span>
                                                                                            )
                                                                                        ) : (
                                                                                            row[column.field]
                                                                                        )}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    };

    return (
        <Box m="20px">
            {dataTable.length > 0 && (
                <>
                    {data.module === 'projects' ? (
                        <SmallHeader title={data.title} subtitle={data.description} />
                    ) : (
                        <Header title={data.title} subtitle={data.description} />
                    )}
                    {data.searchParams?.length > 0 && (
                        <SearchFilters data={data.searchParams} onSubmit={handleSearchSubmit} />
                    )}
                    <Box
                        maxWidth="100%"
                        mx="auto"
                        mt="-10px"
                        sx={{
                            "& .name-column--cell": { color: colors.greenAccent[300] },
                            "& .MuiTableHead-root": {
                                backgroundColor: colors.primary[500],
                                borderBottom: "none",
                                textAlign: "center",
                            },
                            "& .MuiTableCell-head": { color: colors.grey[900], padding: '4px 8px' },
                            "& .MuiTableContainer-root": {
                                backgroundColor: colors.primary[400],
                                padding: '4px 8px',
                                lineHeight: '1',
                            },
                            "& .MuiTableFooter-root": {
                                borderTop: "none",
                                backgroundColor: colors.grey[900],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        {data.module !== 'projects' && (
                            <Box display="flex" justifyContent="flex-end" mb={2}>
                                <Link to={`/${data.module}/addnew`} style={{ textDecoration: 'none' }}>
                                    <Button variant="contained" color="success">
                                        Agregar Nuevo
                                    </Button>
                                </Link>
                            </Box>
                        )}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((column, index) => (
                                            <TableCell key={index} style={{ padding: '20px 8px', textAlign: 'center' }}>
                                                {column.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataTable.map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <TableRow>
                                                {data.tableHeader.map((column, colIndex) => (
                                                    <TableCell
                                                        key={colIndex}
                                                        style={{ padding: '4px 8px', textAlign: 'center' }}
                                                        className={column.field === 'precioVenta' && row.isPrecioVentaMenor ? 'red-text' : ''}
                                                    >
                                                        {column.collapse ? (
                                                            data.module !== 'projects' ? (
                                                                row.tipo === 'Compuesto' ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        color={row.tipo === 'Compuesto' ? 'info' : 'inherit'}
                                                                        style={{
                                                                            width: '70px',
                                                                            height: '30px'
                                                                        }}
                                                                        onClick={row.tipo === 'Compuesto' ? () => toggleNestedTable(rowIndex, row.id) : null}
                                                                    >
                                                                        {row[column.field]}
                                                                    </Button>
                                                                ) : (
                                                                    <span>{row[column.field]}</span>
                                                                )
                                                            ) : (
                                                                <span>{row[column.field]}</span>
                                                            )
                                                        ) : column.field === 'status' && column.botons ? (
                                                            data.module === 'projects' ? (
                                                                <span>{row[column.field]}</span>
                                                            ) : (
                                                                <StatusSwitch
                                                                    key={row.id}
                                                                    row={row}
                                                                    handleStatusOpen={handleStatusOpen}
                                                                />
                                                            )
                                                        ) : column.field === 'acciones' && column.botons ? (
                                                            <Box display="flex" justifyContent="center">
                                                                {Object.keys(column.botons).map((botonKey) => {
                                                                    const boton = column.botons[botonKey];
                                                                    if (column.field === 'acciones') {
                                                                        if (
                                                                            data.module === 'articles' ||
                                                                            data.module === 'compounds'
                                                                        ) {
                                                                            switch (row.module) {
                                                                                case 'articles':
                                                                                case 'compounds':
                                                                                    data.module = row.module;
                                                                                    break;
                                                                            }
                                                                        }
                                                                        return boton.label === 'Eliminar' ? (
                                                                            <Button
                                                                                key={boton.label}
                                                                                variant="contained"
                                                                                color={boton.class}
                                                                                onClick={() => handleFormDelete(row.id)}
                                                                                style={{ marginRight: '5px', minWidth: '25px' }}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </Button>
                                                                        ) : (
                                                                            data.module === 'projects' ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color='error'
                                                                                    style={{ minWidth: '25px' }}
                                                                                    onClick={() => handleFormDelete(row.id)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </Button>
                                                                            ) : (
                                                                                <Link
                                                                                    key={boton.label}
                                                                                    to={`/${data.module}/${boton.action.replace(
                                                                                        '${row.id}',
                                                                                        row.id
                                                                                    )}`}
                                                                                    style={{ marginRight: '5px' }}
                                                                                >
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color={boton.class}
                                                                                        style={{ minWidth: '25px' }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                                    </Button>
                                                                                </Link>
                                                                            )
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </Box>
                                                        ) : (
                                                            row[column.field]
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={data.tableHeader.length}
                                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                                >
                                                    <Collapse in={visibleNestedTable === rowIndex} timeout="auto" unmountOnExit>
                                                        <NestedTable rowData={row} />
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                                <ConfirmationDialog
                                    open={open}
                                    onClose={handleStatusClose}
                                    onConfirm={handleConfirm}
                                    statusValue={statusValue}
                                />
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            )}
        </Box>
    );
};