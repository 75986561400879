import { articlesHeaders, tableData } from "../../data/tableHeaders/articles";
import { GetArticlesList } from "../../components/compounds/compoundList";
import { searchParams } from "../../data/search/articles";

import { ExpandableDataTable } from "../../components/globals/dataTable";
import { subContentHeaders } from "../../data/tableHeaders/subContent/articles";

const Articulos = () => {
    // Parámetros de Configuración para cargar los datos requeridos
    const articlesConfig = {
        id: 1,
        title: "Articulos",
        module: "articles",
        tableHeader: articlesHeaders,
        tableData: tableData,
        description: "Listado de Articulos registrado en el sistema",
        endpoint: "datos/articulosCompletos",
        contentList: "obtener/articulosCompuestos"
    };
    const oldDesign = {
        title: "Articulos",
        module: "articles",
        tableHeader: articlesHeaders,
        subContentHeader: subContentHeaders,
        searchParams: searchParams,
        description: "Listado de Articulos registrado en el sistema",
        endpoint: "datos/articulosCompletos",
        getData: "elementos/compuesto"
    };

    return (
        <>
            <GetArticlesList data={articlesConfig} />
        </>
    );
};

export default Articulos;