import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, Grid, Typography, Paper, Modal, Button } from '@mui/material';

import { tokens } from "../../data/tokens";
import ImageUploader from "../globals/imgUploader";

import { getPostData, getDataArray } from '../../system/getData';
import { CreateSection, CreateProject } from "./addElements";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

import { AddArticles } from "../../components/projects/addElements";
import { SimpleDataTable, ExpandableDataTable } from "../../components/globals/newDataTable";

export const ProjectsList = ({ data }) => {
    const navigate = useNavigate();

    const [rows, setRows] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { config, configData } = UseAppConfig();

    const fetchDataTable = async () => {
        try {
            setLoading(true);
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`);
            const formatData = jsonData.map(row => ({
                ...row,
                status: row.status == 1 ? 'Activo' : 'Desactivado'
            }));
            setRows(formatData);
            console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataTable();
    }, [data.endpoint]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleModalOpen = (section) => {
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <Grid p={(4)} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="end" maxWidth="100%">
                    <Box
                        key="Agregar Nuevo"
                        style={{ position: 'absolute', top: '50px', zIndex: 100 }}
                    >
                        <Button style={{ marginLeft: '10px' }} variant="contained" color="info" onClick={() => handleModalOpen('projects')} >
                            Agregar Nuevo Proyecto
                        </Button>
                    </Box>
                    <SimpleDataTable data={data} />
                </Box>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            width: '50%',
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        <CreateProject data={''} />
                    </Paper>
                </Modal>
            </Grid>
        </Grid >
    );
};

export const ProjectDetails = ({ data }) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [projData, setProjData] = useState(null);
    const [catID, setCatID] = useState(null);
    const [projComp, setProjCompuestos] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const { config, configData } = UseAppConfig();
    const [refresh, setRefresh] = useState(false);

    const fetchData = async () => {
        try {
            const apiData = await getDataArray(`${config.API_BASE_URL}/${data.getData}`, { id: data.id });
            setProjData(apiData.projectDetails[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data.id, data.getData, refresh]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    const handleModalOpen = (id, section) => {
        setModalSection(section);
        setCatID(id);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const ProjectDescription = () => {
        const totalProjectCost = 0;
        return (
            <Box p={(4)}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={3} className='projectImage'>
                        <div>
                            <img src={projData.imagen} alt={projData.nombre} width="100%" height="200" />
                            <ImageUploader imageName="miImagen.jpg" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h2" align="left" gutterBottom>
                                {projData.nombre}
                            </Typography>
                            <Button
                                variant="contained"
                                color='warning'
                                style={{ minWidth: '25px' }}
                                onClick={() => handleModalOpen(projData.id, 'edit')}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </Box>
                        <hr noshade="-1"></hr>
                        <Typography variant="h4" gutterBottom>
                            Descripción:<br/>
                            {projData.descripcion}
                            <hr nosshade="-1"></hr>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Fecha de Creación del Proyecto:<br/>            
                            {projData.fechaCreacion}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Última Fecha de Edición del Proyecto:<br/>            
                            {projData.fechaEdicion}
                            <hr nosshade="-1"></hr>
                        </Typography>
                        <Typography variant="h4" gutterBottom className='totalPrice'>
                            Costo Total Acumulado: <h3>{formatCurrency(totalProjectCost)}</h3>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const DisplayDetails = ({ data }) => {
        return (
            <Box mt={4}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleModalOpen(projData.id, 'categorie')}
                    >
                        Crear Nueva Categoría
                    </Button>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" maxWidth="100%">
                    <Typography variant="h4" gutterBottom>
                        Categorías incluidas en el Proyecto:
                    </Typography>
                </Box>
                <Box mt={4}>
                    <ExpandableDataTable data={data} />
                </Box>
            </Box>
        );
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'categorie':
                return <CreateSection proyID={catID} />;
            case 'projects':
                return <AddArticles proyID={data.id} catID={catID} setModalOpen={setModalOpen} />;
            case 'edit':
                return <CreateProject data={projData} />;
            default:
                return null;
        }
    };

    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box p="20px">
                        <main>
                            <ProjectDescription />
                            <Box mt="20px">
                                <DisplayDetails data={data} />
                            </Box>
                        </main>
                    </Box>
                </Paper>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            minWidth: 700,
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        {renderModalContent()}
                    </Paper>
                </Modal>
            </Grid>
        </Grid>
    );
};