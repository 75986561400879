import React, { createContext, useContext, useEffect, useState } from 'react';
import { getDataArray } from '../system/getData';

// Configuración Global de la Aplicación
let apiURL = '';
let apiPORT = '';
let apiSubDIR = '';
let API_URL = '';

const hostName = window.location.hostname;

if (hostName === 'localhost' || hostName === '127.0.0.1') {
	apiURL 		= 'http://localhost';
	apiPORT 	= '11777';
	apiSubDIR 	= 'api/';
	API_URL 	= `${apiURL}:${apiPORT}/${apiSubDIR}`;
	console.log('APP ejecutandose en entorno Local');
} else if (hostName.includes('softbox')) {
	apiURL 		= 'https://api.preciosunitarios.softbox.mx/';
	apiPORT 	= '';
	apiSubDIR 	= 'api/';
	API_URL 	= `${apiURL}/${apiSubDIR}`;
	console.log('APP ejecutandose en entorno de QA');
} else {
	apiURL 		= 'http://dominiodelaapp.com';
	apiPORT 	= '80';
	apiSubDIR 	= 'api/';
	API_URL 	= `${apiURL}:${apiPORT}/${apiSubDIR}`;
	console.log('APP ejecutandose en entorno de Desarrollo');
}

const config = {
	API_BASE_URL: API_URL,
	SITE_URL: hostName
};

export const AppConfigContext = createContext();

export const AppConfigProvider = ({ children }) => {
	const [configData, setconfigData] = useState(null);
	const [loading, setLoading] = useState(true);

	return (
		<AppConfigContext.Provider value={{ config, configData, loading }}>
			{children}
		</AppConfigContext.Provider>
	);
}

export const UseAppConfig = () => useContext(AppConfigContext);
