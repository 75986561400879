
import { ProjectsList } from "../../components/projects/projectsList";
import { projectList } from "../../data/tableHeaders/projects";

const Catalogs = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
	const projectsConfig = {
		title: "Presupuestos",
		module: "projects",
		tableHeader: projectList,
		description: "Listado de Presupuestos registrados en el sistema",
		endpoint: "listado/proyectos"
	}
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		return (
			<ProjectsList data={projectsConfig} />
		)
	// ========================================================================================
}

export default Catalogs;